<template>
    <div v-if="pinToShow.metadata">
        <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
            <v-tab v-for="item in items" :key="item">
                {{ item }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, key) in items" :key="key">
                <template v-if="key == 0">
                    <div class="pa-3">
                        <span class="text-h6">{{pinToShow.metadata.title}}</span><br>
                        <span class="text-caption" v-html="pinToShow.metadata.address"></span>
                        <v-divider class="my-4"></v-divider>
                        <div class="my-3 provider">
                            <v-img height="48" src="@/assets/img/type2_cable_attached.jpg"></v-img>
                            <div class="d-flex flex-column provider-info">
                                <span class="font-weight-medium">TYPE2 (CABLE ATTACHED)</span>
                                <span class="text-caption">Fast / {{pinToShow.metadata.power}}kW / AC - single phase</span>
                            </div>
                        </div>
                        <div class="my-3 provider">
                            <v-img height="48" src="@/assets/img/combo_ccs_eu.jpg"></v-img>
                            <div class="d-flex flex-column provider-info">
                                <span class="font-weight-medium">COMBO CCS EU</span>
                                <span class="text-caption">Fast / {{pinToShow.metadata.power}}kW / DC</span>
                            </div>
                        </div>
                        <div class="my-3 provider">
                            <v-img height="48" src="@/assets/img/combo_ccs_eu.jpg"></v-img>
                            <div class="d-flex flex-column provider-info">
                                <span class="font-weight-medium">BEST PRICE</span>
                                <span class="text-caption">0.247€/min</span>
                            </div>
                        </div>
                        <div class="d-flex flex-column actions">
                            <v-btn class="my-2" color="primary" @click="$router.push({ name: 'Itinerary' })">{{$t("infoItineraryBtn")}}</v-btn>
                            <!-- <v-btn class="my-2" color="primary" :href="`geo:${pinToShow.location.latitude},${pinToShow.location.longitude}`">Intinéraire</v-btn> -->
                            <v-btn class="my-2" color="primary" :disabled="selectedPinBooking != null" @click="updateShowDialog(true)">{{$t("infoBookSlotBtnLabel")}}</v-btn>
                            <span class="my-2 font-weight-bold text-center" v-if="selectedPinBooking">{{$t("infoSlotBookedWithDate", {date: selectedPinBooking.bookedDate, time: selectedPinBooking.bookedTime})}}</span>
                        </div>
                    </div>
                </template>
                <template v-if="key == 1">
                    <div class="pa-3">
                        <span class="text-h6">{{pinToShow.metadata.title}}</span><br>
                        <span class="text-caption">{{$t("infoPaidCharge")}} </span><br>
                        <span class="text-caption">{{$t("infoManufacturerCharge")}}: DBT CEV</span>
                        <div class="d-flex flex-column">
                            <span class="text-caption">{{$t("infoRequiredPassesBtn")}}:</span>
                            <div class="my-3 required-passes-wrapper">
                                <div class="d-flex align-center require-pass">
                                    <img src="@/assets/img/sodetrel.png" height="48px" width="48px" contain/>
                                    <span class="ml-3 text-caption">Izivia</span>
                                </div>
                                <div class="d-flex align-center require-pass">
                                    <img src="@/assets/img/plugsurfing.png" height="48px" width="48px" contain/>
                                    <span class="ml-3 text-caption">Plug Surfing</span>
                                </div>
                                <div class="d-flex align-center require-pass">
                                    <img src="@/assets/img/freshmile.png" height="48px" width="48px" contain/>
                                    <span class="ml-3 text-caption">Freshmile</span>
                                </div>
                                <div class="d-flex align-center require-pass">
                                    <img src="@/assets/img/chargenow.png" height="48px" width="48px" contain/>
                                    <span class="ml-3 text-caption">Chargenow</span>
                                </div>
                            </div>
                        </div>
                        <v-divider class="my-4"></v-divider>
                        <div class="my-3 provider-wrapper">
                            <div class="provider">
                                <v-img height="48" src="@/assets/img/type2_cable_attached.jpg"></v-img>
                                <div class="d-flex flex-column provider-info">
                                    <span class="font-weight-medium">TYPE2 (CABLE ATTACHED)</span>
                                    <v-chip class="align-self-start" color="green" dark label>Working</v-chip>
                                    <span class="text-caption">Fast / {{pinToShow.metadata.power}}kW / AC - single phase</span>
                                </div>
                            </div>
                            <div>
                                <span class="text-h6">{{$t("infoPricesTitle")}}</span><br>
                                <span class="text-caption">{{$t("infoDurationTitle")}}: 0.247€/min</span><br>
                                <span class="text-caption">{{$t("infoStartingFeesTitle")}}: 1.452€/charge</span><br>
                                <span class="text-caption">{{$t("infoPenalities")}}: 0.151€/min</span>
                            </div>
                        </div>
                        <div class="my-3 provider-wrapper">
                            <div class="provider">
                                <v-img height="48" src="@/assets/img/type2_cable_attached.jpg"></v-img>
                                <div class="d-flex flex-column provider-info">
                                    <span class="font-weight-medium">COMBO CCS EU</span>
                                    <v-chip class="align-self-start" color="black" dark label>Out of service</v-chip>
                                    <span class="text-caption">Fast / {{pinToShow.metadata.power}}kW / AC - single phase</span>
                                </div>
                            </div>
                            <div>
                                <span class="text-h6">{{$t("infoPricesTitle")}}</span><br>
                                <span class="text-caption">{{$t("infoDurationTitle")}}: 0.247€/min</span><br>
                                <span class="text-caption">{{$t("infoStartingFeesTitle")}}: 1.452€/charge</span><br>
                                <span class="text-caption">{{$t("infoPenalities")}}: 0.151€/min</span>
                            </div>
                        </div>
                        <div class="my-3 provider-wrapper">
                            <div class="provider">
                                <v-img height="48" src="@/assets/img/type2_cable_attached.jpg"></v-img>
                                <div class="d-flex flex-column provider-info">
                                    <span class="font-weight-medium">BEST PRICE</span>
                                    <v-chip class="align-self-start" color="green" dark label>Working</v-chip>
                                    <span class="text-caption">Fast / {{pinToShow.metadata.power}}kW / AC - single phase</span>
                                </div>
                            </div>
                            <div>
                                <span class="text-h6">{{$t("infoPricesTitle")}}</span><br>
                                <span class="text-caption">{{$t("infoDurationTitle")}}: 0.247€/min</span><br>
                                <span class="text-caption">{{$t("infoStartingFeesTitle")}} fees: 1.452€/charge</span><br>
                                <span class="text-caption">{{$t("infoPenalities")}}: 0.151€/min</span>
                            </div>
                        </div>
                    </div>
                </template>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            page: {
                title: "infoPageTitle",
                description: null
            },
            items:[ "Infos", "Stations" ],
            text: "Informations",
            tab: 0,
            pinToShow: {}
        }
    },
    created(){
        let id = parseInt(this.$route.params.pinId)
        console.log(typeof id)
        if(typeof id == 'number'){
            sessionStorage.setItem("IEC_GO_TO_INFO", true)
            let formattedPins = JSON.parse(localStorage.getItem("IEC_PINS")) || []
            let selectedPin = formattedPins.filter(pin => pin.metadata.id == id)
            if(selectedPin.length > 0){
                this.pinToShow = selectedPin[0] 
                this.page.title = `Informations ${this.pinToShow.metadata.title}`
                this.page.showBack = true
            }
        }
    },
    methods:{
        ...mapActions("bookingDialog",["updateShowDialog"])
    },
    computed: {
        ...mapGetters({
                selectedPinBooking: "bookingDialog/selectedPinBooking",
            })
    }
}
</script>
<style scoped>
    .provider{
        display: grid;
        grid-template-columns: 48px 1fr;
        grid-column-gap: 16px;
        align-items: center;
    }
    .required-passes-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 16px;
    }
</style>